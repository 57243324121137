import "./src/styles/index.css";
import React from "react";

import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
export const wrapRootElement = ({ element }) => {
  return (
    <GoogleReCaptchaProvider reCaptchaKey="6Ld_tksjAAAAAPQlfdRaF_w5HZEhNEMirKr8DlMf">
      {element}
    </GoogleReCaptchaProvider>
  );
};
