exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-decisions-js": () => import("./../../../src/pages/decisions.js" /* webpackChunkName: "component---src-pages-decisions-js" */),
  "component---src-pages-disclaimer-js": () => import("./../../../src/pages/disclaimer.js" /* webpackChunkName: "component---src-pages-disclaimer-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-thank-you-js": () => import("./../../../src/pages/thank-you.js" /* webpackChunkName: "component---src-pages-thank-you-js" */),
  "component---src-pages-workers-compensation-lawyers-js": () => import("./../../../src/pages/workers-compensation-lawyers.js" /* webpackChunkName: "component---src-pages-workers-compensation-lawyers-js" */),
  "component---src-pages-workplace-injury-lawyers-js": () => import("./../../../src/pages/workplace-injury-lawyers.js" /* webpackChunkName: "component---src-pages-workplace-injury-lawyers-js" */),
  "component---src-pages-wsib-appeal-lawyers-js": () => import("./../../../src/pages/wsib-appeal-lawyers.js" /* webpackChunkName: "component---src-pages-wsib-appeal-lawyers-js" */),
  "component---src-pages-wsib-lawyers-js": () => import("./../../../src/pages/wsib-lawyers.js" /* webpackChunkName: "component---src-pages-wsib-lawyers-js" */),
  "component---src-pages-wsib-lawyers-toronto-js": () => import("./../../../src/pages/wsib-lawyers-toronto.js" /* webpackChunkName: "component---src-pages-wsib-lawyers-toronto-js" */)
}

